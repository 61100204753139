$env: production;
@import '@/styles/_component-base.styles.scss';

.heroImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 67%;

    @include breakpoint(lg) {
        background-position: center center;
    }

    @include responsive-dpi-imgix('/homepage/hero-v1', 'hero-v1', 400, 768, 1440);

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.15;
        background: linear-gradient(to top, rgba(0, 0, 0, 0), #439bc4 75%);
    }
}
